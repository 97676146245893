import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IBike from '../Models/IBike';

/**
 * NewBike component.
 * 
 * This component is used to fetch and display a list of bikes from the API. It also provides a logout functionality.
 * 
 * The bikes are fetched from the API when the component mounts, using the `getData` function. If the fetch is successful, the bikes are stored in the `bikes` state variable.
 * 
 * The `logOut` function clears the local storage and reloads the page, effectively logging out the user.
 * 
 * State:
 * - bikes: An array of bike objects. Each object represents a bike and its details.
 * - setBikes: Function to update the bikes state.
 * 
 * Functions:
 * - getData: Fetches the bike data from the API and updates the bikes state.
 * - logOut: Clears the local storage and reloads the page.
 * 
 * @returns The NewBike component.
 */

export default function NewBike(){

    const apiKey = process.env.REACT_APP_DEEPL_API_KEY;

    React.useEffect(()=> {
        getData();
    }, []);

    const logOut = async () => {
        localStorage.clear();
        window.location.reload();
    }

    const getData = async () => {
        console.log("getData called")
        try {
            const response = await fetch('https://pyorafunction.azurewebsites.net/api/getbikes', {
                method: 'GET',
                headers: {'content-type': 'application/json'}
            });
            const bikes = await response.json();
            if(response.ok){    
                setBikes(bikes)
            }
        } catch (error) {
            console.log("getData error: " + error);
        }
    }

    
    const navigate = useNavigate();
    const [bikes, setBikes] = React.useState<IBike[]>([]);
    const [msg, setmsg] = useState("");
    const newDate = Date.now();
    const [input, setInput] = useState<IBike>({
        id: 1,
        brand: "",
        model: "",
        year: "",
        class: "",
        tireSize: "",
        price: 0,
        notes: "",
        notesEn: ""
    })
    const formd:FormData = new FormData();
    const [image, setImage] = useState(formd);
    const formdataMultiple = new FormData();

    useEffect ( () => {
        console.log("useEffect");
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeNotes = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files !== null){
            const files = Array.from(e.target.files)
            const formdata = new FormData();
            formdata.append(files[0].name, files[0]);
            setImage(formdata);
        }
    }

    const sendImage = async (filename:string, token:string) => {
        console.log("sending image");
        if(image !== null){
            fetch(`https://pyorafunction.azurewebsites.net/api/addbike?filename=${filename}.jpg&username=bikeimages`, {
                method: 'POST',
                headers: {
                    token: token,
                },
                body: image
            })
        }
    }



    const addBike = async () => {
        const newbike:IBike = {
            id: newDate,
            brand: input.brand || "",
            model: input.model || "",
            year: input.year || "",
            class: input.class || "",
            tireSize: input.tireSize || "",
            price: input.price,
            notes: input.notes,
            notesEn: input.notesEn
        }
        const stringified = JSON.stringify(newbike);
        const blob = new Blob([stringified], {type: 'application/json'});
        const bikefile = new File([blob], `${newDate}.json`);
        const formdata = new FormData();
        formdata.append(bikefile.name, bikefile);
        let accessToken = localStorage.getItem('token') || "";
        let accessTokenJSON = JSON.parse(accessToken);
        let token = accessTokenJSON.token || "";
        const response = await fetch(`https://pyorafunction.azurewebsites.net/api/addbike?filename=${newDate}.json&username=bikedata`, {
            method: 'POST',
            headers: {
                token: token,
            },
            body: formdata
        })
        console.log(response);
        setmsg("Status: " + response.statusText);
        const filename = newbike.id.toString();
        var inputValue = (document.getElementById("mainPicture") as HTMLInputElement).value
        if(inputValue != ""){
            sendImage(filename, token);
        }
        setInput(
            {
                id: 1,
                brand: "",
                model: "",
                year: "",
                tireSize: "",
                price: 0,
                notes: "",
                class: ""
            }
        )
     //   window.location.reload();
    }

    const deletebike = async (id:number) => {
        console.log("Poistetaan pyörä id:llä: " + id)
        const response = await fetch(`https://pyorafunction.azurewebsites.net/api/deletebike`, {
            method: 'POST',
            body: JSON.stringify({name: id})
        })
        console.log(response);
        setmsg("Status: " + response.statusText);
        window.location.reload();
    }

    const editBike = useCallback((id:number) => navigate('/editbike/'+id, {replace: true}), [navigate]);

    const translateText = async (text: string, targetLang: string): Promise<string> => {
        const url = process.env.NODE_ENV === 'production' ? 'https://pyorafunction.azurewebsites.net/api/translate' : '/api/translate';
        const body = {
            text: [text],
            target_lang: targetLang
        };
    
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
        return data.translations[0].text;
    };

    const translateNotesToEnglish = async () => {
        try {
            const notesText = input.notes;
            const translatedText = await translateText(notesText, 'EN');
            setInput({
                ...input,
                notesEn: translatedText
            });
        } catch (error) {
            console.error(error);
        }
    }
    

    return (
        <div className="NewBike">
            <h3>Lisää Pyörä</h3>
                <p><input 
                    type="radio"
                    value="M"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="class"
                    id="M"
                />Miesten
                <input 
                    type="radio"
                    value="N"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="class"
                    id="N"
                />Naisten
                <input 
                    type="radio"
                    value="L"
                    className="AddBike-input"
                    onChange={handleChange}
                    checked={input.class === "L"}
                    name="class"
                    id="L"
                />Lasten
                </p>
                <input 
                    type="text"
                    value={input.brand}
                    placeholder="merkki"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="brand"
                />
                <input 
                    type="text"
                    value={input.model}
                    placeholder="malli"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="model"
                />
                <input 
                    type="text"
                    value={input.year}
                    placeholder="vuosimalli"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="year"
                />
                <input 
                    type="text"
                    value={input.tireSize}
                    placeholder="renkaan koko"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="tireSize"
                />
                <input 
                    type="text"
                    value={input.price}
                    placeholder="hinta"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="price"
                />
                <textarea id="notes" className="details" name="notes" value={input.notes} placeholder="Kuvaus" rows={10} onChange={ handleChangeNotes } />
                <button onClick={translateNotesToEnglish}>Käännä englanniksi</button>
                <textarea 
                    id="notesEn" 
                    className="details" 
                    name="notesEn" 
                    value={input.notesEn} 
                    placeholder="Kuvaus englanniksi" 
                    rows={10} 
                    onChange={handleChangeNotes} 
                />
                <h4>Pääkuva: </h4>
                <input type='file' id='mainPicture' onChange={handleSubmit} />
                <button 
                className = "AddBike-btn" onClick={addBike}>
                Lisää pyörä
            </button>
            <h4 >{msg}</h4>
            <p></p>
            <h2>Pyörälistaus</h2>
            <div className="admin-bikeslist">
                {bikes.map(bike => (
                    <div key={bike.id} className  = "admin-bikes">
                        <h3>{bike.brand} {bike.model}</h3>
                        <button className="adminbutton" onClick={() => {
                            const confirmbox = window.confirm("Haluatko varmasti poistaa pyörän?");
                            if(confirmbox){
                                deletebike(bike.id)}}
                        }>Poista</button>
                        <button className="adminbutton" onClick={() => editBike(bike.id)}>Muokkaa</button>
                </div>))}
            </div>
            <button onClick={getData}>Päivitä pyörälistaus</button>
            <div></div>
            <button onClick={logOut}>Kirjaudu ulos</button>
        </div>
    )
}