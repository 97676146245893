import React from "react";
import './footer.css';
import { useTranslation } from 'react-i18next';

/**
 * Footer component.
 * 
 * This is a functional component that renders the footer of the application. 
 * It uses the `useTranslation` hook from `react-i18next` to provide internationalization.
 * 
 * The `useTranslation` hook returns a `t` function that you can use to get the translated text.
 * The keys passed to the `t` function ('footer.businessId', 'footer.phone', and 'footer.email') 
 * correspond to keys in the translation files.
 * 
 * @returns The Footer component.
 */
export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-content">
        <div>
          <p>{t('footer.businessId')}</p>
          <p>{t('footer.phone')}</p>
          <p>{t('footer.email')}</p>
        </div>
      </div>
    </div>
  );
}