import React, { useState } from "react";
import halli1 from "../images/halli1.jpg";
import kartta from "../images/kartta.jpg";
import logo from "../images/logo_iso.jpg";
import { useTranslation } from "react-i18next";

/**
 * Contact component.
 * 
 * This component displays the contact information of Kempeleen Pyöräpaja / Jukka Martinkauppi. The contact information includes the address, email, phone number, and opening hours.
 * 
 * The component also displays two images: a picture of the hall and a map.
 * 
 * @returns The Contact component.
 */

export default function Contact() {
    const { t } = useTranslation();
    return (
      <div className="Contactpage">
            <div className="contact-map">
            <div className="Contactpage-content">
                <h2>{t('contact.title')}</h2>
                <p>{t('contact.address')}</p>
                <p>{t('contact.email')}</p>
                <p>{t('contact.phone')}</p>
                <p></p>
                <p>{t('contact.openingHours')}</p>
            </div>
            <div className="contact-images">
              <img src={halli1} alt="" className="contact-mapimage"/>
              <img src={kartta} alt="" className="contact-mapimage"/>
            </div>
          </div>
      </div>
  )
  }