import React, { useState } from 'react';
import FilterContext from './FilterContext';

const FilterProvider: React.FC = ({ children }) => {
    const [minPriceFilter, setMinPriceFilter] = useState<string | number | null>(null);
    const [maxPriceFilter, setMaxPriceFilter] = useState<string | number | null>(null);
    const [classFilter, setClassFilter] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string>('');

    return (
        <FilterContext.Provider value={{ minPriceFilter, setMinPriceFilter, maxPriceFilter, setMaxPriceFilter, classFilter, setClassFilter, sortOrder, setSortOrder }}>
            {children}
        </FilterContext.Provider>
    );
};

export default FilterProvider;