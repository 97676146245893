import * as React from 'react';
import IBike from '../Models/IBike';

type contextType = {
    bikes: IBike[];
    setBikes: (bikes: IBike[]) => void;
}

const BikeContext = React.createContext<contextType>({
    bikes: [],
    setBikes: (bikes: IBike[]) => { }
});

export default BikeContext;


