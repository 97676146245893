import React from 'react';

interface FilterState {
    minPriceFilter: string | number | null;
    setMinPriceFilter: React.Dispatch<React.SetStateAction<string | number | null>>;
    maxPriceFilter: string | number | null;
    setMaxPriceFilter: React.Dispatch<React.SetStateAction<string | number | null>>;
    classFilter: string | null;
    setClassFilter: React.Dispatch<React.SetStateAction<string | null>>;
    sortOrder: string;
    setSortOrder: React.Dispatch<React.SetStateAction<string>>;
}

const defaultFilterState: FilterState = {
    minPriceFilter: null,
    setMinPriceFilter: () => {},
    maxPriceFilter: null,
    setMaxPriceFilter: () => {},
    classFilter: null,
    setClassFilter: () => {},
    sortOrder: '',
    setSortOrder: () => {},
};

const FilterContext = React.createContext<FilterState>(defaultFilterState);

export default FilterContext;