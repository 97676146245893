import * as React from 'react';
import IBike from './IBike';
import './bike.css';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation, getI18n } from 'react-i18next';

/**
 * BikeDiv component.
 * 
 * This is a functional component that renders a single bike's details. 
 * It uses the `useTranslation` hook from `react-i18next` to provide internationalization.
 * 
 * Props:
 * - Bike: An object representing a bike and its details.
 * 
 * State:
 * - shortNotes: A shortened version of the bike's notes, if the notes are longer than 50 characters.
 * 
 * Functions:
 * - shortNotes: If the bike's notes are longer than 50 characters, it shortens them and adds an ellipsis at the end.
 * 
 * The `useTranslation` hook returns a `t` function that you can use to get the translated text.
 * The keys passed to the `t` function ('bikeDiv.price' and 'bikeDiv.viewMore') 
 * correspond to keys in the translation files.
 * 
 * @returns The BikeDiv component.
 */
export default function BikeDiv (Bike:IBike) {
    const { t } = useTranslation();
    const currentLanguage = getI18n().language;
    var url = 'https://bikes.blob.core.windows.net/bikeimages/'+Bike.id+'.jpg';
    var notes = (currentLanguage === 'en' ? Bike.notesEn : Bike.notes) || "";
    var shortNotes = notes;
    if(notes.length > 50){
        shortNotes = notes.substring(0, 50);
        shortNotes = shortNotes + "...";
    }
    return(  
            <div className="BikeDiv" >
                <img src={url} width = "100%" height="auto"/>
                <h2>{Bike.year} {Bike.brand} {Bike.model} {Bike.tireSize}″</h2>
                <h3>{t('bikeDiv.price')} {Bike.price}€</h3>
                <h3>{shortNotes}</h3>
                <Link to = {'/bike/'+Bike.id} style={{ textDecoration: 'none' }}>
                    {t('bikeDiv.viewMore')}
                </Link>
            </div>
)}
