import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './Navbar.css';
import useToken from "../Components/useToken";
import { useTranslation } from 'react-i18next';
import finFlag from "../images/Flag_of_Finland.jpg";
import enFlag from "../images/Flag_of_the_United_Kingdom.jpg";


function Navbar() {
    const { t, i18n } = useTranslation();
    const {token, setToken} = useToken();
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
    }

    useEffect(() => {
        console.log("useEffect");
        const defaultLanguage = localStorage.getItem('i18nextLng') || 'en';
        i18n.changeLanguage(defaultLanguage);
    }, []);

    
    return (
        <>
            <nav className='navbar'>
                <div className='menu-icon' onClick={handleClick}>
                    <i className="icon-text"><FontAwesomeIcon icon="bars" /></i>
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            {t('navbar.home')}
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/products' className='nav-links' onClick={closeMobileMenu}>
                            {t('navbar.products')}
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
                            {t('navbar.services')}
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            {t('navbar.contact')}
                        </Link>
                    </li>
                    {token? <li className='nav-item'> <Link to='/admin' className='nav-links' onClick={closeMobileMenu}>Admin</Link></li> : null}
                    <li className="nav-item">
                        { i18n.language.startsWith('en') && 
                            <button className="language-button" onClick={() => changeLanguage('fi')}>
                                <img src={finFlag} alt="Finnish flag" />
                            </button> 
                        }
                        { i18n.language.startsWith('fi') &&
                            <button className="language-button" onClick={() => changeLanguage('en')}>
                                <img src={enFlag} alt="Finnish flag" />
                            </button> 
                        }
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar;