import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import IBike from '../Models/IBike';
import BikeContext from "../Components/BikeContext";
import { Link } from 'react-router-dom';

/**
 * EditBike component.
 * 
 * This component is used to edit the details of a selected bike. The bike's id is obtained from the current URL, and the bike's details are fetched from the BikeContext.
 * 
 * The user can update the bike's year, brand, model, tire size, and price. After making changes, the user can click the 'Save' button to save the changes.
 * 
 * State:
 * - bike: An object representing the selected bike and its details.
 * - setBike: Function to update the bike state.
 * 
 * Functions:
 * - saveChanges: Saves the changes made to the bike's details.
 * 
 * @returns The EditBike component.
 */

export default function BikeDetails(){

    const [bikes, setBikes] = React.useState<IBike[]>(useContext(BikeContext).bikes);
    const [msg, setmsg] = useState("");
    const [msg2, setmsg2] = useState("");
    const [msg3, setmsg3] = useState("");
    const formd:FormData = new FormData();
    const [image, setImage] = useState(formd);

    const selectedBike = bikes.find(bike => bike.id === parseInt(window.location.pathname.split('/')[2]));

    var url = 'https://bikes.blob.core.windows.net/bikeimages/'+selectedBike?.id+'.jpg';

    const [input, setInput] = useState<IBike>({
        id: selectedBike?.id || 0,
        brand: selectedBike?.brand,
        model: selectedBike?.model,
        year: selectedBike?.year,
        class: selectedBike?.class,
        tireSize: selectedBike?.tireSize || "",
        price: selectedBike?.price || 0,
        notes: selectedBike?.notes || "",
        notesEn: selectedBike?.notesEn || ""
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeNotes = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files !== null){
            const files = Array.from(e.target.files)
            const formdata = new FormData();
            formdata.append(files[0].name, files[0]);
            setImage(formdata);
        }
    }

    const addImage = async () => {
        let accessToken = localStorage.getItem('token') || "";
        let accessTokenJSON = JSON.parse(accessToken);
        let token = accessTokenJSON.token || "";
        const filename = selectedBike?.id.toString();
        var inputValue = (document.getElementById("mainPicture") as HTMLInputElement).value
        if(inputValue != "" && filename != undefined){
            const response = await sendImage(filename, token);
            if(response){
                setmsg2(response);
            }
        }
    }

    const addSecondImage = async () => {
        let accessToken = localStorage.getItem('token') || "";
        let accessTokenJSON = JSON.parse(accessToken);
        let token = accessTokenJSON.token || "";
        const filename = selectedBike?.id.toString()+"-2";
        var inputValue = (document.getElementById("secondPicture") as HTMLInputElement).value
        if(inputValue != "" && filename != undefined){
            const response = await sendImage(filename, token);
            if(response){
                setmsg3(response);
            }
        }
    }
        

    const sendImage = async (filename:string, token:string) => {
        if(image !== null){
            const response = await fetch(`https://pyorafunction.azurewebsites.net/api/addbike?filename=${filename}.jpg&username=bikeimages`, {
                method: 'POST',
                headers: {
                    token: token,
                },
                body: image
            })
            return (response.statusText);
        }
    }


    const editBike = async () => {
        let bikeID = selectedBike?.id || 0;
        if(bikeID !== 0){
            const newbike:IBike = {
                id: selectedBike?.id || 0,
                brand: input.brand || "",
                model: input.model || "",
                year: input.year || "",
                class: input.class || "",
                tireSize: input.tireSize || "",
                price: input.price,
                notes: input.notes,
                notesEn: input.notesEn
            }
            const stringified = JSON.stringify(newbike);
            const blob = new Blob([stringified], {type: 'application/json'});
            const bikefile = new File([blob], `${bikeID}.json`);
            const formdata = new FormData();
            formdata.append(bikefile.name, bikefile);
            let accessToken = localStorage.getItem('token') || "";
            let accessTokenJSON = JSON.parse(accessToken);
            let token = accessTokenJSON.token || "";
            const response = await fetch(`https://pyorafunction.azurewebsites.net//api/addbike?filename=${bikeID}.json&username=bikedata`, {
                method: 'POST',
                headers: {
                    token: token,
                },
                body: formdata
            })
            console.log(response);
            setmsg("Status: " + response.statusText);
        }
        //const filename = newbike.id.toString();
        var inputValue = (document.getElementById("mainPicture") as HTMLInputElement).value
 
        if(inputValue != ""){
            //sendImage(filename, token);
        }
    }

    
    return (
        <div className="EditBike">
                <h3>Muokkaa pyörää {selectedBike?.brand} {selectedBike?.model}</h3>
                <p><input 
                    type="radio"
                    value="M"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="class"
                    id="M"
                />Miesten
                <input 
                    type="radio"
                    value="N"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="class"
                    id="N"
                />Naisten
                <input 
                    type="radio"
                    value="L"
                    className="AddBike-input"
                    onChange={handleChange}
                    checked={input.class === "L"}
                    name="class"
                    id="L"
                />Lasten
                </p>
                <p>Merkki</p>
                <input 
                    type="text"
                    value={input.brand}
                    placeholder="merkki"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="brand"
                />
                <p>Malli</p>
                <input 
                    type="text"
                    value={input.model}
                    placeholder="malli"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="model"
                />
                <p>Vuosimalli</p>
                <input 
                    type="text"
                    value={input.year}
                    placeholder="vuosimalli"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="year"
                />
                <p>Renkaan koko</p>
                <input 
                    type="text"
                    value={input.tireSize}
                    placeholder="renkaan koko"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="tireSize"
                />
                <p>Hinta</p>
                <input 
                    type="text"
                    value={input.price}
                    placeholder="hinta"
                    className="AddBike-input"
                    onChange={handleChange}
                    name="price"
                />
                <textarea id="notes" className="details" name="notes" value={input.notes} placeholder="Kuvaus" rows={10} onChange={ handleChangeNotes } />
                <textarea id="notes" className="details" name="notesEn" value={input.notesEn} placeholder="Kuvaus englanniksi" rows={10} onChange={ handleChangeNotes } />
                <button className = "AddBike-btn" onClick={editBike}>
                    Muokkaa tietoja
                </button>
                <p>Serverin vastaus: {msg}</p>
                <h4>Pääkuva: </h4>
                <img src={url} width = "50%" height="auto"/>
                <h4>Vaihda pääkuvaa</h4>
                <input type='file' id='mainPicture' onChange={handleSubmit} />
                <button className='AddBike-btn' onClick={addImage}>Päivitä kuva</button>
                <p>Serverin vastaus: {msg2}</p>
                <h4>2. Kuva</h4>
                <input type='file' id='secondPicture' onChange={handleSubmit} />
                <button className='AddBike-btn' onClick={addSecondImage}>Päivitä kuva</button>
                <p>Serverin vastaus: {msg3}</p>
                <h4>3. Kuva</h4>
                </div>
    )
}