import React, { useEffect, useState, useContext, useCallback } from 'react';
import IBike from '../Models/IBike';
import BikeContext from "../Components/BikeContext";
import useToken from "../Components/useToken";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation, getI18n } from 'react-i18next';

/**
 * BikeDetails component.
 * 
 * This component displays the details of a selected bike. The bike's details include its image, year, brand, model, tire size, and price.
 * The bike's id is obtained from the current URL, and the bike's details are fetched from the BikeContext.
 * 
 * The user can also navigate to the edit bike page by clicking the 'Edit' button.
 * 
 * State:
 * - token: The user's authentication token. Can be a string or null.
 * - setToken: Function to update the token state.
 * 
 * Functions:
 * - editBike: Navigates to the edit bike page.
 * 
 * @returns The BikeDetails component.
 */

export default function BikeDetails(){
    const { t } = useTranslation();

    const {token, setToken} = useToken();

    const navigate = useNavigate();

    const editBike = useCallback((id:number) => navigate('/editbike/'+id, {replace: true}), [navigate]);

    const selectedBike = useContext(BikeContext).bikes.find(bike => bike.id === parseInt(window.location.pathname.split('/')[2]));

    var url = 'https://bikes.blob.core.windows.net/bikeimages/'+selectedBike?.id+'.jpg';

    const currentLanguage = getI18n().language;
    var notes = (currentLanguage === 'en' ? selectedBike?.notesEn : selectedBike?.notes) || "";
    
    return (
        <div className="BikeDetails">
            <div className='Content'>
                <div className='BikeInfo'>
                <img src={url} width = "99%" height="auto"/>
                    <h1>{selectedBike?.year} {selectedBike?.brand} {selectedBike?.model} {selectedBike?.tireSize}″</h1>
                    <p>{t('bikeDetails.price')}: {selectedBike?.price}€</p>
                    <h2>{t('bikeDetails.additionalInfo')}</h2>
                    <p>{notes}</p>
                </div>
            </div>
            <Link to='/products' className='backbutton'> {t('bikeDetails.back')}</Link>    
            {token? <button className="adminbutton" onClick={() => editBike(selectedBike?.id || 1)}>Muokkaa</button> : null}
        </div>
    )
}

