import React from 'react';
import logo from "../images/logo_iso.jpg";
import { useTranslation } from 'react-i18next';


export default function Services() {
    const { t } = useTranslation();
    return (
        <div className="Servicespage">
            <div className='Servicespage-content'>
                <h2 className='text'>{t('services.maintenance')}</h2>
                <h4>{t('services.basicMaintenance')}</h4>
                <ul>
                    <li>{t('services.singleSpeed')} 50€</li>
                    <li>{t('services.hubGears')} 60€</li>
                    <li>{t('services.derailleurGears')} 80€ </li>
                </ul>
                <h4 className='text'>{t('services.basicMaintenanceIncludes')}:</h4>
                <ul>
                    <li>{t('services.bikeWash')}</li>
                    <li>{t('services.chainCleaningAndLubrication')}</li>
                    <li>{t('services.wheelStraightening')}</li>
                    <li>{t('services.cableCheck')}</li>
                    <li>{t('services.gearAndBrakeAdjustment')}</li>
                    <li>{t('services.hubAndSteeringBearingCheck')}</li>
                    <li>{t('services.boltTightening')}</li>
                </ul>
                <p>{t('services.partsReplacementChargedSeparately')}</p> 
                <p>{t('services.limitedElectricBikeMaintenance')}</p>
                <h2 className='text'>{t('services.repairs')}</h2>
                <h4>{t('services.examples')}:</h4>
                <ul>
                    <li>{t('services.outerTubeReplacement')}: 
                    <ul>
                        <li>{t('services.frontOrRearWheel')} 10€</li>
                        <li>{t('services.detachableWheel')} 8€</li>
                    </ul>
                    </li>
                    <li>{t('services.innerTubeReplacementOrPatch')} 10€</li>
                    <li>{t('services.brakePadReplacement')} 10€</li>
                    <li>{t('services.chainReplacement')} 10€</li>
                    <li>{t('services.cableReplacement')} 10€</li>
                    <li>{t('services.mudguardInstallation')} 15-30€</li>
                    <li>{t('services.frontBasketInstallation')} 15€</li>
                    <li>{t('services.kickstandInstallation')} 10€</li>
                    <li>{t('services.saddleReplacement')} 5€</li>
                    <li>{t('services.pedalReplacement')} 5€</li>
                    <li>{t('services.handlebarReplacement')} 10€</li>
                </ul>
            </div>
        </div>
    )
    }